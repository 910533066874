import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AuthenticationGuard } from './core/auth/authentication.guard';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';


const routes: Routes = [
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    //loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
    redirectTo: 'account/login'
  },

  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'contact-us',
    data: { breadcrumb: 'Contact Us' },
    loadChildren: () => import('./contact/contact.module').then(mod => mod.ContactModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'jobseeker',
    data: { breadcrumb: 'Job Seeker' },
    loadChildren: () => import('./job-seeker/job-seeker.module').then(m => m.JobSeekerModule)
  },
  {
    path: 'employee',
    data: { breadcrumb: 'Employee' },
    loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule)
  },
  {
    path: 'employer',
    data: { breadcrumb: 'Employee' },
    loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule)
  },
  {
    path: 'district',
    data: { breadcrumb: 'District' },
    loadChildren: () => import('./district/district.module').then(m => m.DistrictModule)
  },
  {
    path: 'regional',
    data: { breadcrumb: 'Regional' },
    loadChildren: () => import('./regional/regional.module').then(m => m.RegionalModule)
  },
  {
    path: 'state',
    data: { breadcrumb: 'State' },
    //loadChildren: () => import('./state/state.module').then(m => m.StateModule)
    loadChildren: () => import('./state/state.module').then(m => m.StateModule)
  },
  // {
  //   path: 'jobfair',
  //   data: { breadcrumb: 'Job Fair' },
  //   loadChildren: () => import('./job-fair/job-fair.module').then(m => m.JobFairModule)
  // },
  {
    path: 'institute',
    data: { breadcrumb: 'Institute' },
    loadChildren: () => import('./institute/institute.module').then(m => m.InstituteModule)
  },
  {
    path: 'notice-board',
    data: { breadcrumb: 'Notificatons' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),
    data: { breadcrumb: "Gallery" }
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
   // loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },

  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },
  { path: 'jobfair', loadChildren: () => import('./job-fair/job-fair.module').then(m => m.JobFairModule) },
  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
  },



 { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) },
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },
  //{ path: "layout", loadChildren: () => import(`./all-modules/all-modules.module`).then((m) => m.AllModulesModule),canActivate: [AuthenticationGuard],},
  { path: '**', redirectTo: '/login'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
