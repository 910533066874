import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { JwtModule } from '@auth0/angular-jwt';
import { SharedModule } from 'primeng/api';
export function tokenGetter() {
  return localStorage.getItem("token");
}
const hostname = window && window.location && window.location.hostname;
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    QRCodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "localhost:44366", "chehrmsapi.orpgujarat.com","chehrms.orpgujarat.com","dettms.orpgujarat.com", 'dettmsapi.orpgujarat.com', "dethrms.orpgujarat.com", "sepapi.orpgujarat.com"],
        //disallowedRoutes: [""],
      }
    }),
    SharedModule,

    DataTablesModule,
    ToastrModule.forRoot(
      {
        timeOut: 1500,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
  ],
  providers: [

    {
     //provide: 'API_BASE_URL', useValue: 'https://localhost:44366/'
    //  provide: 'API_BASE_URL', useValue: 'https://dethrmsapi.orpgujarat.com/'
    //provide: 'API_BASE_URL', useValue: 'https://chehrmsapi.orpgujarat.com/'
   //provide: 'API_BASE_URL', useValue: 'https://dettmsapi.orpgujarat.com/'
    provide: 'API_BASE_URL',
       useValue: (/^.*localhost.*/.test(hostname)) ? 'https://localhost:44366/' : (/^dettms.orpgujarat.com/.test(hostname)) ? 'https://dettmsapi.orpgujarat.com/' : 'https://dettmsapi.orpgujarat.com/'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
